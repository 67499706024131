<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">Consider the reaction shown below:</p>
      <p class="mb-5 pl-8">
        <chemical-latex content="2 A(g) <=> B(g)" />
        <stemble-latex content="$\qquad \text{K}_\text{p}=$" />
        <number-value :value="KpValue" />
      </p>

      <p class="mb-3">
        If the reaction vessel begins with
        <number-value :value="PAnaught" unit="\text{bar}" />
        of
        <chemical-latex content="A(g)" />
        at
        <stemble-latex content="$450\,\text{K,}$" />
        determine the pressure of
        <chemical-latex content="B(g)" />
        when the system reaches equilibrium.
      </p>

      <calculation-input
        v-model="inputs.PB"
        prepend-text="$\text{P}_\text{B}:$"
        append-text="$\text{bar}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question121',
  components: {
    StembleLatex,
    ChemicalLatex,
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        PB: null,
      },
    };
  },
  computed: {
    PAnaught() {
      return this.taskState.getValueBySymbol('PAnaught').content;
    },
    KpValue() {
      return this.taskState.getValueBySymbol('KpValue').content;
    },
  },
};
</script>
